import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signup.css';
import Modal from '../../Components/Modal';
import { Helmet } from 'react-helmet';

function SignupComponent() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // New loading state
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true); // Set loading to true

        try {
            const response = await fetch("https://lucky-back-2.onrender.com/api/createUser", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, email, password, location })
            });

            const data = await response.json();

            if (!response.ok) {
                setIsModalOpen(true);

                throw new Error(data.message || 'Invalid password or Email');

            }

            // On successful signup, open the modal
            setIsModalOpen(true);
            setSuccess(" you have successfully signed up redirect to login page");

            // Optionally, redirect to login page after a successful signup
            setTimeout(() => {
                navigate('/');
            }, 3000); // Redirect after 2 seconds

            // Optionally, reset form fields
            setEmail('');
            setPassword('');
            setName('');
            setLocation('');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    return (

        <div className="login-container">
            <Helmet>
                <title>Sign Up</title>
                <meta name="description" content="
    Sign up for an account"/>

            </Helmet>
            <div className="login-form">
                <h2>Sign Up</h2>
                <p>Please enter your details.</p>

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Full Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={handleNameChange}
                            required
                            placeholder="Enter your full name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            placeholder="Enter your email"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            placeholder="Enter your password"
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="submit-button" disabled={loading}>
                            {loading ? 'Registering...' : 'Register'}
                        </button>

                        <a href="#" className="forgot-password">Already have an account?</a>
                    </div>
                </form>

            </div>

            <Modal show={isModalOpen} onClose={handleCloseModal}>
                {success && <h2 className='success-message'>{success}</h2>}

                {error && <h2 className='error-message'>{error}</h2>}
                <div className="modal-actions">

                </div>
            </Modal>
        </div>
    );
}

export default SignupComponent;
