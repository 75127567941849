import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import Modal from '../../Components/Modal';
import { Helmet } from 'react-helmet';


function LoginComponent() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);
        // Set loading to true

        try {
            const response = await fetch("https://lucky-back-2.onrender.com/api/loginUser", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            const data = await response.json();

            if (!response.ok) {
                setIsModalOpen(true);
                setLoading(false)
                throw new Error(data.message || 'Something went wrong!');


            }
            if (data.success) {

                localStorage.setItem("authToken", data.authToken)
                console.log(localStorage.getItem("authToken"))
                setSuccess('Login Successful');
                setLoading(false);
                setIsModalOpen(true);

                setTimeout(() => {
                    navigate('/', { state: { email } });
                    window.location.reload()// Redirect after 2 seconds
                }, 2000);
            }
        } catch (err) {
            setError(err.message);
        }
        finally {
            setLoading(false);
            // Reset loading state
        }
    };



    return (
        <div>
            <Helmet>
                <title>Login - Lucky Impex</title>
                <meta name="description" content="Log in to your Lucky Impex account." />
            </Helmet>
            <div className="login-container">

                <div className="login-form">
                    <h2>Log in</h2>
                    <p>Welcome back! Please enter your details.</p>

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                                placeholder="Enter your password"
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="submit-button" disabled={loading}>
                                {loading ? 'Signing in...' : 'Sign in'}
                            </button>
                            <a href="#" className="forgot-password">Forgot password?</a>
                        </div>

                    </form>

                </div>

            </div>
            <Modal show={isModalOpen} onClose={handleCloseModal}>
                {success && <h2 className='success-message'>{success}</h2>}

                {error && <h2 className='error-message'>{error}</h2>}
                <div className="modal-actions">

                </div>
            </Modal>
        </div>

    );
}

export default LoginComponent;
