import React, { useEffect, useState } from 'react'; // Import React and hooks
import { Link } from 'react-router-dom'; // Import Link component for routing

const OrderComponent = () => {
    const [orders, setOrders] = useState([]); // State to store fetched orders
    const [loading, setLoading] = useState(true); // State to handle loading status
    const [error, setError] = useState(null); // State to handle errors

    // Fetch orders data when the component mounts
    const handleOrderData = async () => {
        try {
            const response = await fetch('https://lucky-back-2.onrender.com/api/orders');

            if (!response.ok) {
                throw new Error('Failed to fetch orders');
            }

            const data = await response.json();
            setOrders(data);
        } catch (error) {
            setError(error.message); // Handle errors
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch orders on component mount
    useEffect(() => {
        handleOrderData();
    }, []); // Empty dependency array means it only runs once after the component mounts

    // Loading state rendering
    if (loading) {
        return (
            <div className="loading-container">
                <div className="spinner"></div>
                <img className="spinner-gif" src="spinner.gif" alt="loading products..." />
                <p>Loading....</p>
            </div>
        );
    }

    // Error state rendering
    if (error) {
        return <div>Error: {error}</div>;
    }

    // Render orders
    return (
        <div>
            <h1>Orders</h1>

            {/* Conditionally render orders */}
            {orders.length === 0 ? (
                <p>No orders found.</p>
            ) : (
                <ul>
                    {orders.map(order => (
                        <li key={order.id}>
                            <h2>Order ID: {order._id}</h2>

                            {/* Render order items */}
                            {order.items && order.items.length > 0 ? (
                                <ul>
                                    {order.items.map(item => (
                                        <li key={item.id}>
                                            <p>Item ID: {item.id}</p>
                                            <p>Item Name: {item.name}</p>
                                            <p>Quantity: {item.quantity}</p>
                                            <p>Price: ₹{item.price}</p>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No items in this order.</p>
                            )}

                            <p>Total Price: ₹{order.totalPrice}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default OrderComponent;
